import React from 'react'
import { PlayGrid, TopIntro } from '$components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Col } from '../components/TopIntro'

const Jon = () => (
  <Layout>
    <SEO title="Play" />

    <TopIntro variation="playColor">
      <Col>
        <h1>play</h1>
        <p>
        I’m always keen to collaborate with other designers or illustrators 
        <p>on personal projects, <a href="mailto:me@belgiles.com" target="_blank">Drop me a line</a> if you have an idea!</p>
        </p>
      </Col>
    </TopIntro>

    <PlayGrid noHeading />
  </Layout>
)

export default Jon
